import React, { useState } from "react";
import { fallDown as Menu } from "react-burger-menu";
import { Link } from "gatsby";
import "./burger.css";
import Logo from "./logo";
import menuArrow from "../../../images/icons/menuArrow.png";

const BurgerMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleStateChange = (state) => {
    setMenuOpen(state.isOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };
  return (
    <Menu
      right
      isOpen={menuOpen}
      onStateChange={(state) => handleStateChange(state)}
    >
      <Link id="home" to="/">
        <div
          role="link"
          onClick={() => {
            closeMenu();
          }}
          onKeyDown={() => {
            closeMenu();
          }}
          tabIndex={0}
        >
          <div className="logo-menu">
            <Logo />
          </div>
        </div>
      </Link>
      <div className="flex-btns">
        <button
          onClick={() => {
            closeMenu();
          }}
          onKeyDown={() => {
            closeMenu();
          }}
          id="compra"
        >
          <Link style={{ color: "white" }} to="/reserva-collar-kolyy">
            <b>Reserva tu collar</b>
          </Link>
        </button>
      </div>
      <nav className="bm-item-list">
        {/* <Link id="0perrosperdidos" to="/0perrosperdidos" className="menu-item">
          <div
            role="link"
            className="anchor-div"
            onClick={() => {
              closeMenu();
            }}
            onKeyDown={() => {
              closeMenu();
            }}
            tabIndex={0}
          >
            {" "}
            #0perrosperdidos
            <img src={menuArrow} alt="iniciativa #0perrosPerdidos" />
          </div>
        </Link> */}
        <Link id="razas-de-perro" to="/razas-de-perro" className="menu-item">
          <div
            role="link"
            className="anchor-div"
            onClick={() => {
              closeMenu();
            }}
            onKeyDown={() => {
              closeMenu();
            }}
            tabIndex={0}
          >
            {" "}
            Conoce a tu perro
            <img src={menuArrow} alt="doggipedia" />
          </div>
        </Link>
        <Link id="blog" to="/blog-para-perros" className="menu-item">
          <div
            role="link"
            className="anchor-div"
            onClick={() => {
              closeMenu();
            }}
            onKeyDown={() => {
              closeMenu();
            }}
            tabIndex={0}
          >
            Blog
            <img src={menuArrow} alt="blog" />
          </div>
        </Link>
      </nav>
    </Menu>
  );
};

export default BurgerMenu;
