// extracted by mini-css-extract-plugin
export var active = "header-module--active--byAzz";
export var active_white = "header-module--active_white--P9arU";
export var burger = "header-module--burger--ZYCyO";
export var button_header = "header-module--button_header--AtxDb";
export var disabled = "header-module--disabled--RjaxF";
export var drop_down = "header-module--drop_down--XOzGM";
export var drop_down_content = "header-module--drop_down_content--v1Q+d";
export var flex_nav_btn = "header-module--flex_nav_btn--d69xt";
export var header_out = "header-module--header_out--zyuCd";
export var icon = "header-module--icon--S7vcO";
export var login_link = "header-module--login_link--NcYMU";
export var logo = "header-module--logo--WAoX3";
export var logo_nav_container = "header-module--logo_nav_container--EGiFK";
export var menu_fijo = "header-module--menu_fijo--XT6k4";
export var menu_fijo_out = "header-module--menu_fijo_out--qlETI";
export var navBar = "header-module--navBar--pJgdf";