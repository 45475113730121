// extracted by mini-css-extract-plugin
export var contact = "footer-module--contact--KtqP4";
export var contact_div = "footer-module--contact_div--cazhZ";
export var contact_icons = "footer-module--contact_icons--vraCj";
export var container = "footer-module--container--wjHmS";
export var copyright = "footer-module--copyright--Bg9Rz";
export var div = "footer-module--div--o68f6";
export var flex_container = "footer-module--flex_container--FsQVJ";
export var flex_footer_lang = "footer-module--flex_footer_lang--Zmt-L";
export var flex_links = "footer-module--flex_links--67LU9";
export var intro = "footer-module--intro--qCaY1";
export var locationIcon = "footer-module--locationIcon--rseyW";
export var logo_text = "footer-module--logo_text--lNXV+";
export var media_container = "footer-module--media_container--4GZ8F";
export var media_list = "footer-module--media_list--QUOah";
export var privacity_container = "footer-module--privacity_container--h0Abv";